import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      //首页
      {
        path: '/Home',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      //西铁行
      {
        path: '/WestRailTrain',
        name: 'WestRailTrain',
        component: () => import('../views/WestRailTrain.vue')
      },
      //农场
      {
        path: '/Farm',
        name: 'Farm',
        component: () => import('../views/Farm.vue')
      },
      //养老
      {
        path: '/OldPeopleHome',
        name: 'OldPeopleHome',
        component: () => import('../views/OldPeopleHome.vue')
      },
      //锅炉
      {
        path: '/Boiler',
        name: 'Boiler',
        component: () => import('../views/Boiler.vue')
      },
      //指挥中心
      {
        path: '/CommandCenter',
        name: 'CommandCenter',
        component: () => import('../views/CommandCenter.vue')
      },
      //中药盒
      {
        path: '/ChinMedicineBox',
        name: 'ChinMedicineBox',
        component: () => import('../views/ChinMedicineBox.vue')
      },
      //药盒
      {
        path: '/PillCase',
        name: 'PillCase',
        component: () => import('../views/PillCase.vue')
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
