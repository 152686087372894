<template>
  <div class="home">
    <div class="top">
      <img class="homelog" src="../static/img/homelog.png" alt="">
      <div class="toptext">
        <router-link to="/Home">
          <div class="text1" ref="home" @click="homeClick()"
            :style="homeTab == 0 ? `background: linear-gradient(90deg, #013775 0%, #000D3C 100%); color: #FFFFFF;font-weight: bold;` : ``">
            首页</div>
        </router-link>
        <div class="text2" @click="anliClick()"
          :style="homeTab == 1 ? `background: linear-gradient(90deg, #013775 0%, #000D3C 100%); color: #FFFFFF;font-weight: bold;` : ``">
          成功案例
          <div class="show" v-if="isshow">
            <router-link to="/WestRailTrain">
              <div @click="show()">西铁行物联平台</div>
            </router-link>
            <router-link to="/Farm">
              <div @click="show()">智慧农场</div>
            </router-link>
            <router-link to="/OldPeopleHome">
              <div @click="show()">智能养老</div>
            </router-link>
            <router-link to="/Boiler">
              <div @click="show()">智能锅炉</div>
            </router-link>
            <router-link to="/CommandCenter">
              <div @click="show()">集团企业指挥中心</div>
            </router-link>
            <router-link to="/ChinMedicineBox">
              <div @click="show()">智能中药盒</div>
            </router-link>
            <router-link to="/PillCase">
              <div @click="show()">准时药盒</div>
            </router-link>
          </div>
        </div>
        <!-- <div class="text3">关于我们</div> -->
      </div>
      <img class="phone" src="../static/img/phone.png" alt="">
    </div>
    <div class="top_"></div>
    <router-view />
  </div>
</template>

<script>

import store from '../store'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  data() {
    return {
      isHome: true,
      isAnli: false,
      isshow: false
    }
  },
  methods: {
    showout() {
      store.commit('sethomeTab', 2)

    },
    show() {
      store.commit('sethomeTab', 1)
    },
    //点击首页
    homeClick() {
      store.commit('sethomeTab', 0)
      this.isHome = true
      this.isAnli = false
      this.isshow = false

    },
    //点击相关案例
    anliClick() {
      // store.commit('sethomeTab', 1)
      if (this.isAnli && this.isshow) {
        this.isshow = false;
      } else {
        this.isshow = true;
      }
      this.isAnli = true;
      this.isHome = false;
    },
    clickout() {
      this.isshow = false;
    }
  },
  computed: {

    ...mapState(['homeTab'])
  },
  created() {
  },
  mounted() {
    console.log(this.$refs.home)
    this.$refs.home.click()
    store.commit('sethomeTab', 0)
  },

}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}


.top {
  width: 100vw;
  height: 100px;
  background-color: #FFFFFF;
  // background-color: aquamarine;
  position: fixed;
  top: 0;
  display: flex;
  z-index: 10;
  justify-content: space-between;
}

.top_ {
  width: 90vw;
  margin: auto;
  height: 100px;
}

.homelog {
  width: 144px;
  height: 42px;
  margin-top: 42px;
  margin-left: 100px;
}

.phone {
  width: 169px;
  margin-top: 36px;
  height: 52px;
  margin-right: 144px;
}

.toptext {
  margin-top: 50px;
  display: flex;

  @media (max-width: 1200px) {
    .text3 {
      display: none;
    }
  }

  @media (max-width: 950px) {
    .text1 {
      display: none;
    }
  }

  @media (max-width: 810px) {
    .text2 {
      display: none;
    }
  }

  .text1 {
    height: 24px;
    // font-weight: bold;
    // color: #FFFFFF;
    padding: 8px 42px;
    border-radius: 20px;
    color: #333333;
    text-decoration: none;
    // background: linear-gradient(90deg, #013775 0%, #000D3C 100%);
  }

  .text2:hover {
    cursor: pointer;
  }

  .text2 {
    height: 24px;
    border-radius: 20px;
    // margin-left: 0%;
    margin-left: 100px;
    padding: 8px 42px;
    position: relative;
    text-decoration: none;



    .show {
      width: 200px;
      background-color: #F8F8F8;
      position: absolute;
      z-index: 10;
      top: 50px;
      left: 20px;
      font-size: 16px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #333333;
      padding: 10px 0px;
      border-radius: 10px;
      opacity: 0.8;

      a {
        text-decoration: none;
      }

      a:hover {
        background-color: #333333;
      }



      div {
        padding: 5px 25px;
        color: #333333;

      }

      div:hover {
        background-color: #14305c;
        color: #FFFFFF;
      }
    }

  }

  .text3 {
    height: 24px;
    border-radius: 20px;
    margin-left: 100px;
    padding: 8px 42px;
    text-decoration: none;

  }
}
</style>
