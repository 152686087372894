import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homeTab: 0,
    // showState: false
  },
  getters: {
  },
  mutations: {
    sethomeTab(state, homeTab) {
      state.homeTab = homeTab
    },
    // setshowState(state, showState) {
    //   state.showState = showState
    //   console.log(state.showState)
    // }
  },
  actions: {
  },
  modules: {
  }
})
